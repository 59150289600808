export const routes = [
    {
        path: '/appointment_status',
        name: 'appointment_status.browse',
        component: () => import(/* webpackChunkName: "AppointmentStatusBrowse" */ '@/views/app/AppointmentStatusBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointment_status/create',
        name: 'appointment_status.create',
        component: () => import(/* webpackChunkName: "AppointmentStatusActions" */ '@/views/app/AppointmentStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointment_status/:id/edit',
        name: 'appointment_status.edit',
        component: () => import(/* webpackChunkName: "AppointmentStatusActions" */ '@/views/app/AppointmentStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/appointment_status/:id/delete',
        name: 'appointment_status.delete',
        component: () => import(/* webpackChunkName: "AppointmentStatusActions" */ '@/views/app/AppointmentStatusActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]